<template>
  <div>
    <!-- Clear Selected search criteria and consumers,  Selected Search Criteria-->
    <vx-card class="mb-8">
      <div class="card-title more-card-title flex justify-between items-center">
        <div class="flex items-center flex-wrap">
          <h2 class="w-auto mb-2">{{ LabelConstant.headingLabelSelectedCriteria }}</h2>
          <p class="table-data-entries-details text-left mb-2">
            {{ LabelConstant.labelVisitorsIdentified }} : <span>{{ totalConsumer | tableRecordNumberFormatter }}</span>
          </p>
        </div>
        <!-- Clear Selected search criteria and consumer -->
        <vs-button
          color="grey"
          type="border"
          class="grey-btn sm:mt-0 mt-2"
          icon-pack="feather"
          icon="icon-refresh-ccw"
          @click="clearSearchCriteria()"
        >{{ LabelConstant.buttonLabelClearSearchCriteria }}</vs-button>
      </div>
      <!-- Selected Search Criteria -->
      <div class="vx-row find-people">
        <div class="vx-col w-full">
          <input-tag v-model="tags" @input="testUpdateSelectedTags"></input-tag>
        </div>
      </div>
    </vx-card>
    
    <!-- Search Criteria Filter, consumer Table -->
    <vx-card>
      <div class="vx-row find-people search-lead">
        <!-- Search Criteria Filter -->
        <div class="vx-col xl:w-1/4 col-cus-12 mb-8 w-full">
          <div class="px-0 search-filters">
            <div slot="header" class="filter-header pl-0">{{ LabelConstant.headingLabelSearchCriteria }}</div>
            <vs-collapse accordion class="px-0">
              <!-- date   -->
              <div class="filter-card">
                <div class="my-3 left-side-datepicker">
                  <div slot="header">Date</div>
                  <date-range-picker
                    ref="lastVisitedDynamicType"
                    class="float-unset"
                    :fromDateValue="fromDate"
                    :toDateValue="toDate"
                    @inputFromDate="(newFromDate) => {fromDate = newFromDate}"
                    @inputToDate="(newToDate) => {toDate = newToDate}"
                  ></date-range-picker>
                </div>
              </div>
              <!-- Personal criteria  --> 
              <div class="filter-card">
                <vs-collapse-item>
                  <div slot="header">Personal Criteria</div>
                  <div class="filter-list">
                    <vs-checkbox v-model="showNameText" @click="firstName = null,lastName=null">{{ LabelConstant.inputLabelName }}</vs-checkbox>
                    <div v-if="showNameText">
                      <vs-input
                        v-model="firstName"
                        class="mb-2 w-full"
                        placeholder="Type first name here"
                      />
                      <vs-input
                        v-model="lastName"
                        class="w-full"
                        placeholder="Type last name here"
                      />
                    </div>
                    <vs-checkbox v-model="showEmailAddress" @click="emailAddress=null">Email Address</vs-checkbox>
                    <div v-if="showEmailAddress">
                      <vs-input
                        v-model="emailAddress"
                        class="w-full"
                        placeholder="Type email address here"
                      />
                    </div>
                    <vs-checkbox v-model="showGenderOptions" @click="Gender = []">Gender</vs-checkbox>
                    <div v-if="showGenderOptions">
                      <v-select
                        class="multiple-select type-multiselect"
                        :label="'Value'"
                        v-model="gender"
                        :options="gendersOptions"
                        multiple
                      />
                    </div>
                    <vs-checkbox v-model="showAgeRangeOptions" @click="ageRange = []">Age Range</vs-checkbox>
                    <div v-if="showAgeRangeOptions">
                      <v-select
                        class="multiple-select type-multiselect"
                        :label="'Value'"
                        :options="ageRangesOptions"
                        v-model="ageRange"
                        multiple
                      />
                    </div>
                    <vs-checkbox v-model="married">Married</vs-checkbox>
                    <vs-checkbox v-model="hasChildren">Has Children</vs-checkbox>
                  </div>
                </vs-collapse-item>
              </div>
              <!-- Location   -->
              <div class="filter-card">
                <vs-collapse-item>
                  <div slot="header">Location Criteria</div>
                  <div class="filter-list">
                    <vs-checkbox v-model="showStates" @click="states = []">State</vs-checkbox>
                    <div v-if="showStates">
                      <v-select
                        class="multiple-select type-multiselect"
                        :label="'Value'"
                        :options="statesOptions"
                        v-model="states"
                        multiple
                      />
                    </div>
                    <vs-checkbox v-model="showCity" @click="city = []">City</vs-checkbox>
                    <div v-if="showCity">
                      <input-tag
                        v-model="city"
                        :addTagOnBlur="true"
                        :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]"
                        @input="termSeparation(city,'City')"
                      ></input-tag>
                    </div>
                    <vs-checkbox v-model="showZipCode" @click="zipCode = []">Zip</vs-checkbox>
                    <div v-if="showZipCode">
                      <input-tag
                        v-model="zipCode"
                        :addTagOnBlur="true"
                        :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]"
                        @input="termSeparation(zipCode,'Zip')"
                      ></input-tag>
                    </div>
                  </div>
                </vs-collapse-item>
              </div>
              <!-- Financial criteria  -->
              <div class="filter-card">
                <vs-collapse-item>
                  <div slot="header">Financial Criteria</div>
                  <div class="filter-list">
                    <vs-checkbox v-model="showAnnualIncome" @click="annualIncome = []">Annual Income</vs-checkbox>
                    <div v-if="showAnnualIncome">
                      <v-select
                        class="multiple-select type-multiselect"
                        :label="'Value'"
                        v-model="annualIncome"
                        :options="annualIncomeRangesOptions"
                        multiple
                      />
                    </div>
                    <vs-checkbox v-model="showNetWorth" @click="netWorth = []">Net Worth</vs-checkbox>
                    <div v-if="showNetWorth">
                      <v-select
                        class="multiple-select type-multiselect"
                        :label="'Value'"
                        :options="netWorthRangesOptions"
                        v-model="netWorth"
                        multiple
                      />
                    </div>
                    <vs-checkbox v-model="ownHome">Own Home</vs-checkbox>
                  </div>
                </vs-collapse-item>
              </div>
              <!-- Marketing criteria  -->
              <div class="filter-card">
                <vs-collapse-item>
                  <div slot="header">Marketing Criteria</div>
                  <div class="filter-list">
                    <vs-checkbox v-model="showDomains" @click="domains = []">Domains</vs-checkbox>
                    <div v-if="showDomains">
                      <input-tag
                        :addTagOnBlur="true"
                        placeholder="type Domain"
                        v-model="domains"
                        :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]"
                        @input="termSeparation(domains,'Domains')"
                      ></input-tag>
                    </div>
                    <vs-checkbox v-model="showReferralSource" @click="referralSource = []">Referral Source</vs-checkbox>
                    <div v-if="showReferralSource">
                      <input-tag
                        :addTagOnBlur="true"
                        placeholder="type in referral source here"
                        v-model="referralSource"
                        :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]"
                        @input="termSeparation(referralSource,'ReferralSource')"
                      ></input-tag>
                    </div>
                    <vs-checkbox v-model="showKeyword" @click="keyword = []">Keyword</vs-checkbox>
                    <div v-if="showKeyword">
                      <input-tag
                        :addTagOnBlur="true"
                        placeholder="Type keyword here"
                        v-model="keyword"
                        :add-tag-on-keys="[ 13 , 188 , 9 , 186 ]"
                        @input="termSeparation(keyword,'Keyword')"
                      ></input-tag>
                    </div>
                    <vs-checkbox v-model="showPageViewed" @click="pageViewed=null">Page Viewed</vs-checkbox>
                    <div v-if="showPageViewed">
                      <vs-input
                        v-model="pageViewed"
                        class="w-full"
                        placeholder="Type page viewed here"
                      />
                    </div>
                  </div>
                </vs-collapse-item>
              </div>
            </vs-collapse>
          </div>
          <!-- buttons -->
          <div class="pt-5 mt-5 flex">
            <vs-button
              :title="LabelConstant.buttonLabelSearch"
              color="primary"
              type="filled"
              class="mr-1 px-5"
              @click="searchConsumer()"
            >{{ LabelConstant.buttonLabelSearch }}</vs-button>
          </div>
        </div>
        <!-- searched-consumer table-->
        <div class="vx-col xl:w-3/4 col-cus-12 mb-8 w-full border-left">
          <div>
            <vs-navbar class="nabarx mb-8 table-nav">
              <div slot="title">
                <vs-navbar-title> Search Your Visitors </vs-navbar-title>
              </div>
              <vs-navbar-item index="0">
                <dropdown-menu class="dropdown-export">
                  <button slot="trigger" class="dropdown-export-btn">
                    {{ LabelConstant.buttonLabelExport }}
                    <i class="vs-icon notranslate icon-scale material-icons null">expand_more</i>
                  </button>
                  <ul slot="body">
                    <li @click="exportConsumer('csv')"> {{ LabelConstant.buttonLabelExportCSV }} </li>
                    <li @click="exportConsumer('excel')"> {{ LabelConstant.buttonLabelExportExcel }} </li>
                  </ul>
                </dropdown-menu>
              </vs-navbar-item>
              <v-select
                class="ml-2 show-tbl-data"
                label="value"
                :options="showEntriesOptions"
                :reduce="(option) => option.value"
                :clearable="false"
                v-model="recordPerMainPage"
                @input="searchConsumerPerPage()"
              />
            </vs-navbar>
          </div>
          <!-- Find consumer table-->
          <div class="common-table pt-0">
            <!-- table -->
            <vs-table multiple v-model="selectedMain" :data="consumers">
              <template slot="thead">
                <vs-th>{{ LabelConstant.tableHeaderLabelName }}</vs-th>
                <vs-th>{{ LabelConstant.tableHeaderLabelVisited }}</vs-th>
                <vs-th>{{ LabelConstant.tableHeaderLabelLocation }} </vs-th>
                <vs-th>{{ LabelConstant.tableHeaderLabelSite }}</vs-th>
                <vs-th>{{ LabelConstant.tableHeaderLabelSource }}</vs-th>
                <vs-th>{{ LabelConstant.tableHeaderLabelKeyword }}</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].Name">
                    <a class="cursor-pointer" @click="consumerDetailPopup=true,getLeadDetail(data[indextr].Id)">{{ data[indextr].Name }}</a> 
                  </vs-td>
                  <vs-td :data="data[indextr].Date_Last_Visited">
                    {{ data[indextr].Date_Last_Visited }}
                  </vs-td>
                  <vs-td :data="data[indextr].Location">
                    {{ data[indextr].Location }}
                  </vs-td>
                  <vs-td :data="data[indextr].Site">
                    {{ data[indextr].Site }}
                  </vs-td>
                  <vs-td :data="data[indextr].Source">
                    {{ data[indextr].Source }}
                  </vs-td>
                  <vs-td :data="data[indextr].Keyword">
                    {{ data[indextr].Keyword }}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <!-- Total consumer and Pagination -->
            <div class="vx-row clear-both">
              <div class="vx-col w-full mt-4 lg:w-full">
                <div class="flex items-center justify-between paginate-row flex-wrap">
                  <p class="table-data-entries-details">
                    {{ LabelConstant.labelVisitorsIdentified }} : <span>{{ totalConsumer | tableRecordNumberFormatter }}</span>
                  </p>
                  <b-pagination
                    pills
                    size="sm"
                    class="pr-3 bootstrap-pagination"
                    v-model="currentPage"
                    :total-rows="totalConsumer"
                    :per-page="recordPerMainPage"
                    :limit="1"
                    prev-text="< Prev"
                    next-text="Next >"
                    :hide-goto-end-buttons="true"
                    @change="searchConsumerPerPage()"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>
    
    <!-- Download Files -->
    <div v-show="false">
      <xlsx-workbook>
        <xlsx-sheet
          :collection="downloadConsumer.data"
          v-for="downloadConsumer in downloadConsumer"
          :key="downloadConsumer.name"
          :sheet-name="downloadConsumer.name"
        />
        <xlsx-download :filename="'Consumer.csv'" :options="{ bookType: 'csv' }">
          <a ref="selectedToCsv">{{ LabelConstant.buttonLabelExportCSV }}</a>
        </xlsx-download>
      </xlsx-workbook>
    </div>
    <div v-show="false">
      <xlsx-workbook>
        <xlsx-sheet
          :collection="downloadConsumer.data"
          v-for="downloadConsumer in downloadConsumer"
          :key="downloadConsumer.name"
          :sheet-name="downloadConsumer.name"
        />
        <xlsx-download :filename="'Consumer.xlsx'" :options="{ bookType: 'xlsx' }">
          <a ref="selectedToExcel">{{ LabelConstant.buttonLabelExportExcel }}</a>
        </xlsx-download>
      </xlsx-workbook>
    </div>
    
    <!-- consumer Detail pop up -->
    <consumer-lead-detail-modal
      :consumerDetailPopup="consumerDetailPopup"
      @close="closepopup"
      :consumerLeadId="consumerLeadId"
      :consumerDetail="consumerDetail"
    ></consumer-lead-detail-modal>
    
    <!-- select cunsumer pop up -->
    <find-any-one-select-contact-add-to-saved-warning-modal
      :popupTitle="selecteconsumersWarningTitle"
      :isPopup="selecteconsumersWarning"
      :selectContactWarningMessage="infoSelectconsumersToExport"
      @clickForFinish="selecteconsumersWarning = false"
    ></find-any-one-select-contact-add-to-saved-warning-modal>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import InputTag from "vue-input-tag";
import vSelect from "vue-select";
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
import { BPagination } from "bootstrap-vue";
import DropdownMenu from "v-dropdown-menu";
import "v-dropdown-menu/dist/v-dropdown-menu.css";
import DateRangePicker from "../../../components/filter/DateRangePicker";
import ConsumerLeadDetailModal from "../../../components/modal/consumer/ConsumerLeadDetailModal";
import FindAnyOneSelectContactAddToSavedWarningModal from "../../../components/modal/FindAnyOneSelectContactAddToSavedWarningModal.vue";
import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);

export default {
  components: {
    InputTag,
    "v-select": vSelect,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    BPagination,
    DropdownMenu,
    DateRangePicker,
    ConsumerLeadDetailModal,
    FindAnyOneSelectContactAddToSavedWarningModal,
  },
  data: () => ({     
    // for store all selected search criteria
    tagsTempArr: [],

    // Data
    fromDate: "01/01/2022",
    toDate: "01/01/2022",

    //personal Criteria
    showNameText: false,
    firstName: null,
    lastName: null,

    showEmailAddress: false,
    emailAddress: null,

    showGenderOptions: false,
    gender: [],
    gendersOptions:[],

    showAgeRangeOptions: false,
    ageRange: [],
    ageRangesOptions:[],

    married: false,
    hasChildren: false,
    
    // Location Criteria
    showStates: false,
    states: [],
    statesOptions:[],
    
    showCity: false,
    city: [],

    showZipCode: false,
    zipCode: [],

    // Financial criteria
    showAnnualIncome: false,
    annualIncome: [],
    annualIncomeRangesOptions:[],

    showNetWorth: false,
    netWorth: [],
    netWorthRangesOptions:[],

    ownHome: false,

    // Marketing criteria 
    showDomains:false,
    domains: [],

    showReferralSource: false,
    referralSource: [],

    showKeyword: false,
    keyword: [],

    showPageViewed:false,
    pageViewed:null,
    
    // page
    totalConsumer: 0,
    recordPerMainPage: 10,
    currentPage: 1,
    showEntriesOptions: [
      { key: 10, value: 10 },
      { key: 25, value: 25 },
      { key: 50, value: 50 },
      { key: 100, value: 100 },
    ],
    
    // consumer detail
    consumers: [],
    consumerLeadId:null,
    searchParams: null,
    selectedMain: [],

    // Consumer Detail Pop up
    consumerDetailPopup:false,
    consumerDetail: null,

    // Download Files
    downloadConsumer:null,
    selecteconsumersWarning:false,
    selecteconsumersWarningTitle:"Download Consumer",
    infoSelectconsumersToExport:"Please select consumers to export.",

    downloadContacts: null,  
  }),
  computed: {
    tags: {
      get: function () {
        let arr = [];
        let that = this;
        //Search Criteria Input       
        if(!arr.includes(this.fromDate) &&!arr.includes(this.toDate) && this.fromDate!=null && this.toDate!=null){
          arr.push("Date:"+this.fromDate+'-'+this.toDate);
        }
        if (!arr.includes(this.firstName) && this.firstName!=null && this.firstName!="") {
          arr.push("First Name:" + this.firstName);
        }
        if (!arr.includes(this.lastName) && this.lastName!=null && this.lastName!="") {
          arr.push("Last Name:" + this.lastName);
        }
        if (!arr.includes(this.emailAddress) && this.emailAddress!=null && this.emailAddress!="") {
          arr.push("Email:" + this.emailAddress);
        }
        for (let i = 0; i < this.gender.length; i++) {
          if (!arr.includes(this.gender[i])) {
            arr.push("Gender:" + this.gender[i].Value);
          }
        }
        for (let i = 0; i < this.ageRange.length; i++) {
          if (!arr.includes(this.ageRange[i])) {
            arr.push("Age Range:" + this.ageRange[i].Value);
          }
        }
        if (this.married) {
          arr.push("Married:Yes");
        }
        if (this.hasChildren) {
          arr.push("Has Children:Yes");
        }
        for (let i = 0; i < this.states.length; i++) {
          if (!arr.includes(this.states[i])) {
            arr.push("State:" + this.states[i].Value);
          }
        }
        for (let i = 0; i < this.city.length; i++) {
          if (!arr.includes(this.city[i])) {
            arr.push("City:" + this.city[i]);
          }
        }
        for (let i = 0; i < this.zipCode.length; i++) {
          if (!arr.includes(this.zipCode[i])) {
            arr.push("Zip:" + this.zipCode[i]);
          }
        }
        for (let i = 0; i < this.annualIncome.length; i++) {
          if (!arr.includes(this.annualIncome[i])) {
            arr.push("Annual Income:" + this.annualIncome[i].Value);
          }
        }
        for (let i = 0; i < this.netWorth.length; i++) {
          if (!arr.includes(this.netWorth[i])) {
            arr.push("Net Worth:" + this.netWorth[i].Value);
          }
        }
        if (this.ownHome) {
          arr.push("Own Home:Yes");
        }
        for (let i = 0; i < this.domains.length; i++) {
          if (!arr.includes(this.domains[i])) {
            arr.push("Domain:" + this.domains[i]);
          }
        }
        for (let i = 0; i < this.referralSource.length; i++) {
          if (!arr.includes(this.referralSource[i])) {
            arr.push("Referral Source:" + this.referralSource[i]);
          }
        }
        for (let i = 0; i < this.keyword.length; i++) {
          if (!arr.includes(this.keyword[i])) {
            arr.push("Keyword:" + this.keyword[i]);
          }
        }
        if (!arr.includes(this.pageViewed) && this.pageViewed!=null && this.pageViewed!="") {
          arr.push("Page Viewed:" + this.pageViewed);
        }
        that.tagsTempArr = [];
        that.tagsTempArr = arr;
        return arr;
      },
      set: function () {},
    },
  },
  created() { 
    this.getListAgeRanges();
    this.getListAnnualIncomeRanges();
    this.getListNetWorthRanges();
    this.getListGenders();
    this.getListStates();
    this.setFromDate();
    this.setToDate();
  },
  mounted(){
    this.searchConsumer();
  },
  methods: {
    formattedDate(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss.SSS");
    },
    // get drop down values
    getListAgeRanges(){
      this.$vs.loading();
      this.axios.get("/ws/ConsumerLeads/ListAgeRanges")
        .then((response) => {
          let data = response.data;
          this.ageRangesOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    getListAnnualIncomeRanges(){
      this.$vs.loading();
      this.axios.get("/ws/ConsumerLeads/ListAnnualIncomeRanges")
        .then((response) => {
          let data = response.data;
          this.annualIncomeRangesOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    getListGenders(){
      this.$vs.loading();
      this.axios.get("/ws/ConsumerLeads/ListGenders")
        .then((response) => {
          let data = response.data;
          this.gendersOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    getListStates(){
      this.$vs.loading();
      this.axios.get("/ws/ConsumerLeads/ListStates")
        .then((response) => {
          let data = response.data;
          this.statesOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    }, 
    getListNetWorthRanges(){
      this.$vs.loading();
      this.axios.get("/ws/ConsumerLeads/ListNetWorthRanges")
        .then((response) => {
          let data = response.data;
          this.netWorthRangesOptions = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    // for remove duplicate value from array
    arrayRemove(arr, value) {
      return arr.filter(function (ele) {
        return ele != value;
      });
    },
    // change in Selected Criteria Input
    testUpdateSelectedTags(value) {
      let keyArr = this.tagsTempArr.filter((x) => value.indexOf(x) === -1);
      let testStr = keyArr[0];
      let keyStr = keyArr[0].substring(keyArr[0].indexOf(":") + 1);

      if (testStr.includes("Date:")) {
        this.fromDate=null
        this.toDate=null
        this.setFromDate();
        this.setToDate();
      }
      if (testStr.includes("First Name:")) {
        this.firstName = null;
      }
      if (testStr.includes("Last Name:")) {
        this.lastName = null;
      }
      if (testStr.includes("Email:")) {
        this.emailAddress = null;
      }
      if (testStr.includes("Gender:")) {
        let removableIndex = this.gender.findIndex(function (arr) {
          if (arr.Value == keyStr) return true;
        });
        this.gender.splice(removableIndex, 1);
      }
      if (testStr.includes("Age Range:")) {
        let removableIndex = this.ageRange.findIndex(function (arr) {
          if (arr.Value == keyStr) return true;
        });
        this.ageRange.splice(removableIndex, 1);
      }
      if (testStr.includes("Married:")) {
        this.married = false;
      }
      if (testStr.includes("Has Children:")) {
        this.hasChildren = false;
      }
      if (testStr.includes("State:")) {
        let removableIndex = this.states.findIndex(function (arr) {
          if (arr.Value == keyStr) return true;
        });
        this.states.splice(removableIndex, 1);
      }
      if (testStr.includes("City:")) {
        this.city = this.arrayRemove(this.city, keyStr);
      }
      if (testStr.includes("Zip:")) {
        this.zipCode = this.arrayRemove(this.zipCode, keyStr);
      }
      if (testStr.includes("Annual Income:")) {
        let removableIndex = this.annualIncome.findIndex(function (arr) {
          if (arr.Value == keyStr) return true;
        });
        this.annualIncome.splice(removableIndex, 1);
      }
      if (testStr.includes("Net Worth:")) {
        let removableIndex = this.netWorth.findIndex(function (arr) {
          if (arr.Value == keyStr) return true;
        });
        this.netWorth.splice(removableIndex, 1);
      }
      if (testStr.includes("Own Home:")) {
        this.ownHome = false;
      }
      if (testStr.includes("Domain:")) {
        this.domains = this.arrayRemove(this.domains, keyStr);
      }
      if (testStr.includes("Referral Source:")) {
        this.referralSource = this.arrayRemove(this.referralSource, keyStr);
      }
      if (testStr.includes("Keyword:")) {
        this.keyword = this.arrayRemove(this.keyword, keyStr);
      }
      if (testStr.includes("Page Viewed:")) {
        this.pageViewed = null;
      }
    },
    // clear search criteria
    clearSearchCriteria() {
      this.showNameText= false;
      this.showEmailAddress= false;
      this.showGenderOptions= false;
      this.showAgeRangeOptions= false;
      this.married= false;
      this.hasChildren= false;
      this.showStates= false;
      this.showCity= false;
      this.showZipCode= false;
      this.showAnnualIncome= false;
      this.showNetWorth= false;
      this.ownHome= false;
      this.showDomains=false;
      this.showReferralSource= false;
      this.showKeyword= false;
      this.showPageViewed= false;
    
      this.firstName= null;
      this.lastName= null;
      this.emailAddress= null;
      this.gender= [];
      this.ageRange= [];
      this.states= [];
      this.city= [];
      this.zipCode= [];
      this.annualIncome= [];
      this.netWorth= [];
      this.domains= [];
      this.referralSource= [];
      this.keyword= [];
      this.pageViewed= null;
    },
    // search Consumer params
    searchConsumerParam(){
      // Data
      let isDateLastVisitedSelected=true;
      let lastVisitedDateFrom= "2000-01-01T00:00:00.000Z";
      let lastVisitedDateTo= "2000-01-01T00:00:00.000Z";
      let dateLastVisitedDynamicType=this.checkDateRanges(this.fromDate,this.toDate);
      if(dateLastVisitedDynamicType=='custom range'){
        dateLastVisitedDynamicType=null;
        lastVisitedDateFrom= this.formattedDate(this.fromDate);
        lastVisitedDateTo= this.formattedDate(this.toDate);
      }    

      // personal Criteria
      let isFullNameSelected = false;
      let firstNameParam=null;
      if (this.firstName){
        isFullNameSelected = true;
        firstNameParam=this.firstName
      }

      let lastNameParam=null;
      if (this.lastName){
        isFullNameSelected = true;
        lastNameParam=this.lastName
      }

      let isEmailAddressSelected = false;
      let emailAddressParam=null;
      if (this.emailAddress){
        isEmailAddressSelected = true;
        emailAddressParam=this.emailAddress
      }

      let isGenderSelected = false;
      let gendersParam=[];
      if(this.gender.length > 0) {
        isGenderSelected = true;
        for (let i = 0; i < this.gender.length; i++) {
          gendersParam.push({
            Text: this.gender[i].Key,
          });
        }
      }
      gendersParam=gendersParam.length==0?null:gendersParam

      let isAgeRangeSelected = false;
      let ageRangesParam=[];
      if(this.ageRange.length > 0) {
        isAgeRangeSelected = true;
        for (let i = 0; i < this.ageRange.length; i++) {
          ageRangesParam.push({
            Text: this.ageRange[i].Key,
          });
        }
      }
      ageRangesParam=ageRangesParam.length==0?null:ageRangesParam

      // Location Criteria
      let isConsumerStateSelected = false;
      let consumerStatesParam =[];
      if(this.states.length > 0) {
        isConsumerStateSelected = true;
        for (let i = 0; i < this.states.length; i++) {
          consumerStatesParam.push({
            Text: this.states[i].Key,
          });
        }
      }
      consumerStatesParam=consumerStatesParam.length==0?null:consumerStatesParam

      let isConsumerCitySelected = false;
      let consumerCitiesParam=[];
      if(this.city.length > 0) {
        isConsumerCitySelected = true;
        for (let i = 0; i < this.city.length; i++) {
          consumerCitiesParam.push({
            Text: this.city[i],
          });
        }
      }
      consumerCitiesParam=consumerCitiesParam.length==0?null:consumerCitiesParam

      let isConsumerZipSelected = false;
      let consumerZipsParam=[];
      if(this.zipCode.length > 0) {
        isConsumerZipSelected = true;
        for (let i = 0; i < this.zipCode.length; i++) {
          consumerZipsParam.push({
            Text: this.zipCode[i],
          });
        }
      }
      consumerZipsParam=consumerZipsParam.length==0?null:consumerZipsParam

      // Financial criteria 
      let isAnnualIncomeRangeSelected = false;
      let annualIncomeRangesParam=[];
      if(this.annualIncome.length > 0) {
        isAnnualIncomeRangeSelected = true;
        for (let i = 0; i < this.annualIncome.length; i++) {
          annualIncomeRangesParam.push({
            Text: this.annualIncome[i].Key,
          });
        }
      }
      annualIncomeRangesParam=annualIncomeRangesParam.length==0?null:annualIncomeRangesParam

      let isNetWorthRangeSelected = false;
      let netWorthRangesParam=[];
      if(this.netWorth.length > 0) {
        isNetWorthRangeSelected = true;
        for (let i = 0; i < this.netWorth.length; i++) {
          netWorthRangesParam.push({
            Text: this.netWorth[i].Key,
          });
        }
      }
      netWorthRangesParam=netWorthRangesParam.length==0?null:netWorthRangesParam

      // Marketing criteria 
      let isDomainSelected = false;
      let domainsParam=[];
      if(this.domains.length > 0) {
        isDomainSelected = true;
        for (let i = 0; i < this.domains.length; i++) {
          domainsParam.push({
            Text: this.domains[i],
          });
        }
      }
      domainsParam=domainsParam.length==0?null:domainsParam

      let isReferralSourceSelected = false;
      let referralSourcesParam=[];
      if(this.referralSource.length > 0) {
        isReferralSourceSelected = true;
        for (let i = 0; i < this.referralSource.length; i++) {
          referralSourcesParam.push({
            Text: this.referralSource[i],
          });
        }
      }
      referralSourcesParam=referralSourcesParam.length==0?null:referralSourcesParam

      let isKeywordSelected = false;
      let keywordsParam=[];
      if(this.keyword.length > 0) {
        isKeywordSelected = true;
        for (let i = 0; i < this.keyword.length; i++) {
          keywordsParam.push({
            Text: this.keyword[i],
          });
        }
      }
      keywordsParam=keywordsParam.length==0?null:keywordsParam

      let isPageViewedSelected = false;
      let pageViewedParam=null;
      if (this.pageViewed){
        isPageViewedSelected = true;
        pageViewedParam=this.pageViewed
      }

      this.searchParams={
        IsFullNameSelected: isFullNameSelected,
        FirstName: firstNameParam,
        LastName: lastNameParam,
        IsEmailAddressSelected: isEmailAddressSelected,
        EmailAddress: emailAddressParam,
        IsGenderSelected: isGenderSelected,
        Genders: gendersParam,
        IsAgeRangeSelected: isAgeRangeSelected,
        AgeRanges: ageRangesParam,
        IsMarriedSelected: this.married,
        IsHasChildrenSelected: this.hasChildren,
        IsConsumerStateSelected: isConsumerStateSelected,
        ConsumerStates: consumerStatesParam,
        IsConsumerCitySelected: isConsumerCitySelected,
        ConsumerCities: consumerCitiesParam,
        IsConsumerZipSelected: isConsumerZipSelected,
        ConsumerZips: consumerZipsParam,
        IsAnnualIncomeRangeSelected: isAnnualIncomeRangeSelected,
        AnnualIncomeRanges: annualIncomeRangesParam,
        IsNetWorthRangeSelected: isNetWorthRangeSelected,
        NetWorthRanges: netWorthRangesParam,
        IsDomainSelected: isDomainSelected,
        Domains: domainsParam,
        IsReferralSourceSelected: isReferralSourceSelected,
        ReferralSources: referralSourcesParam,
        IsKeywordSelected: isKeywordSelected,
        Keywords: keywordsParam,
        IsOwnHomeSelected: this.ownHome,
        IsPageViewedSelected:isPageViewedSelected,
        PageViewed: pageViewedParam,

        Page: this.currentPage,
        PageSize: this.recordPerMainPage,

        IsDateLastVisitedSelected: isDateLastVisitedSelected,
        DateLastVisitedDateFrom: lastVisitedDateFrom,
        DateLastVisitedDateTo: lastVisitedDateTo,
        DateLastVisitedDynamicType: dateLastVisitedDynamicType,
      }
    },
    // search Consumer 
    searchConsumer(){
      this.consumers=[]
      this.currentPage=1
      this.searchConsumerParam();

      this.$vs.loading();
      this.axios.post("/ws/ConsumerLeads/SearchConsumerLeads",this.searchParams)
        .then((response) => {
          let data = response.data;
          this.consumers=data.ConsumerLeads?data.ConsumerLeads:[]; 
          this.searchConsumerLeadsCount(this.searchParams);
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    // search Consumer by page
    searchConsumerPerPage(){
      setTimeout(() => {
        this.searchParams.Page=this.currentPage;
        this.searchParams.PageSize=this.recordPerMainPage;
        if(this.totalConsumer >= 10){
          this.axios.post("/ws/ConsumerLeads/SearchConsumerLeads",this.searchParams)
            .then((response) => {
              let data = response.data;
              this.consumers=data.ConsumerLeads?data.ConsumerLeads:[];  
            })
        }
       }, 1000); 
    },
    // DateLastVisitedDynamicType Value
    checkDateRanges(start,end){
      let selectedStart = start;
      let selectedEnd = end;
      let dateRanges =this.$refs.lastVisitedDynamicType ? this.$refs.lastVisitedDynamicType.$refs.picker.ranges : {};
      if((moment(dateRanges['This month'][0]).format("MM/DD/YYYY") == selectedStart) && moment(dateRanges['This month'][1]).format("MM/DD/YYYY") == selectedEnd){
        return "this month";
      } else if((moment(dateRanges['Last month'][0]).format("MM/DD/YYYY") == selectedStart) && moment(dateRanges['Last month'][1]).format("MM/DD/YYYY") == selectedEnd){
        return "last month";
      } else if(moment(dateRanges['This year'][0]).format("MM/DD/YYYY") == selectedStart && moment(dateRanges['This year'][1]).format("MM/DD/YYYY") == selectedEnd){
        return "this year";
      } else if(moment(dateRanges['Today'][0]).format("MM/DD/YYYY") == selectedStart && moment(dateRanges['Today'][1]).format("MM/DD/YYYY") == selectedEnd){
        return "today";
      } else if(moment(dateRanges['Yesterday'][0]).format("MM/DD/YYYY") == selectedStart && moment(dateRanges['Yesterday'][1]).format("MM/DD/YYYY") == selectedEnd){
        return "yesterday";
      } else {
        return "custom range";
      }
    },
    // open lead detail
    getLeadDetail(id){
      this.consumerLeadId=id
      this.consumerDetail={}
      this.$vs.loading();
      this.axios.get("/ws/ConsumerLeads/GetConsumerLeadDetail?visitorId="+id)
        .then((response) => {
          let data = response.data;
          this.$vs.loading.close();
          this.consumerDetail=data
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
              text: e.response.data.Errors[0].Message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
              time: 4000,
          });
          this.showError(e);
        });
    },
    // close lead detail pop up
    closepopup(){
      this.selectedMain=[]
      this.consumerDetailPopup=false
    },
    // comma and semicolon separated input tag
    termSeparation(values,name){
      if(values[values.length-1]){
        let singleValue=values[values.length-1]
        let splitValue=singleValue.split(/[,|;]/);
        let valueSeprated=[]
        splitValue.forEach(e => {
          if(e.trim().length){
            valueSeprated.push(e.trim());
          }
        });

        if(name=="City"){
          this.city.pop();
          this.city=this.city.concat(valueSeprated);
          this.city=[...new Set(this.city)];
        }
        if(name=="Zip"){
          this.zipCode.pop();
          this.zipCode=this.zipCode.concat(valueSeprated);
          this.zipCode=[...new Set(this.zipCode)];
        }
        if(name=="Domains"){
          this.domains.pop();
          this.domains=this.domains.concat(valueSeprated);
          this.domains=[...new Set(this.domains)];
        }
        if(name=="ReferralSource"){
          this.referralSource.pop();
          this.referralSource=this.referralSource.concat(valueSeprated);
          this.referralSource=[...new Set(this.referralSource)];
        }
        if(name=="Keyword"){
          this.keyword.pop();
          this.keyword=this.keyword.concat(valueSeprated);
          this.keyword=[...new Set(this.keyword)];
        }
      }
    },
    // Total consumers form the search
    searchConsumerLeadsCount(searchParams){
      this.axios.post("/ws/ConsumerLeads/GetSearchConsumerLeadsCount",searchParams)
        .then((response)=>{
          let data=response.data;
          this.totalConsumer=data;
        })
    },
    // file download
    exportConsumer(type){
      this.$vs.loading();
      this.axios.post("/ws/ConsumerLeads/ExportConsumerLeads",this.searchParams)
        .then((response) => {
          let data = response.data;
          if(data.length > 0){
            this.downloadConsumer = [{ name: "Consumer", data: data }];
            setTimeout(() => {
              if(type == 'csv'){
                this.$refs.selectedToCsv.click();
              } else {
                this.$refs.selectedToExcel.click();
              }
            }, 1000);
          }else{
            this.$vs.notify({
              title: "Error",
              text: "Can't Download, File data is not available!",
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
              time: 4000
            });
          }
          this.$vs.loading().close;
        })
        .catch((e) => {
          this.showError(e);
        });
    },
  },
};
</script>